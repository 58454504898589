import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../../components/InnerPage';

const DieSeeleZitate = () => (
    <InnerPage title="Zitate – Die Seele" description="">
        <IntroHolder
            title="Zitate"
            subtitle="Die Seele"
            breadcrumbs={{
                greatgrandparent: 'Woran Bahá’í glauben',
                grandparent: 'Die Natur des Menschen',
                parent: 'Die Seele',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Ährenlese, LXXXII">
                    Wisse wahrlich, daß die Seele ein Zeichen Gottes ist, ein
                    himmlischer Edelstein, dessen Wirklichkeit die gelehrtesten
                    Menschen nicht zu begreifen vermögen, und dessen Geheimnis
                    kein noch so scharfer Verstand je zu enträtseln hoffen kann.
                    Sie ist von allen erschaffenen Dingen das erste, das die
                    Vollkommenheit des Schöpfers verkündet, Seine Herrlichkeit
                    anerkennt, sich an Seine Wahrheit hält und sich in Anbetung
                    vor Ihm niederbeugt. Wenn sie Gott treu ist, wird sie Sein
                    Licht widerstrahlen und schließlich zu Ihm zurückkehren.
                    Wenn sie jedoch die Treuepflicht gegenüber ihrem Schöpfer
                    vergißt, wird sie ein Opfer des Selbstes und der
                    Leidenschaften wer­den und am Ende in deren Abgründen
                    versinken.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese, XXIX">
                    Die Absicht Gottes bei der Erschaffung des Menschen war und
                    wird immer sein, ihn zu befähigen, seinen Schöpfer zu
                    erkennen und in Seine Gegenwart zu gelangen … Wer immer den
                    Tagesanbruch göttlicher Führung anerkennt und Seinen
                    heiligen Hof betritt, ist Gott nahegekommen und hat Seine
                    Gegenwart erreicht, eine Gegenwart, die das wahre Paradies
                    ist und für das die erhabensten Wohnstätten des Himmels nur
                    ein Sinnbild sind.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Verborgenen Worte, Persisch 8">
                    Eile voran auf dem Pfade der Heiligkeit und tritt ein in den
                    Himmel der Gemeinschaft mit Mir. Läutere dein Herz mit der
                    Flamme des Geistes und eile zum Hofe des Allhöchsten.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Ansprachen in Paris">
                    Wenn die Frucht des Baumes zum Vorschein kommt und reif
                    wird, wissen wir, dass der Baum vollkommen ist. Wenn der
                    Baum nicht Früchte trüge, wäre er nur ein nutzloses Gewächs,
                    das keinen Zweck hat! Wenn eine Seele das geistige Leben in
                    sich trägt, dann bringt sie gute Früchte hervor und wird sie
                    zu einem göttlichen Baume.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Göttliche Lebenskunst">
                    Auf dieser Welt muß er sich daher für das jenseitige Leben
                    vorbereiten. Hier muß er das erwerben, was er in der Welt
                    des Königreiches braucht.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Verborgenen Worte, Arabisch 1    ">
                    Dies ist Mein erster Rat: Besitze ein reines, gütiges und
                    strahlendes Herz, damit du unvergängliche Souveränität
                    erlangest, während von Ewigkeit zu Ewigkeit.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Ansprachen in Paris">
                    Wenn ein Mensch in seinen Gedanken fortgesetzt den
                    himmlischen Dingen zustrebt, wird er wie ein Heiliger, wenn
                    sich seine Gedanken hingegen nicht erheben, sondern abwärts
                    trachten, um sich in den Mittelpunkt der Dinge dieser Welt
                    zu stellen, wird er fortwährend materieller, bis er einen
                    Zustand erreicht, der wenig besser als der des bloßen Tieres
                    ist.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese, LXXXII">
                    Wisse wahrlich, daß die Seele des Menschen, wenn sie auf den
                    Wegen Gottes gewandelt ist, gewiß zurückkehrt und zur
                    Herrlichkeit des Geliebten versammelt wird.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Verborgenen Worte, Arabisch 32">
                    O Sohn des Höchsten!
                    <br />
                    Den Tod machte Ich dir zum Boten der Freude. Warum bist du
                    traurig? Das Licht erschuf Ich, dich zu erleuchten. Warum
                    verhüllst du dich vor ihm?
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese LXXXI">
                    Wisse wahrlich, daß die Seele nach ihrer Trennung vom Leibe
                    weiter fortschreitet, bis sie die Gegenwart Gottes erreicht,
                    in einem Zustand und einer Beschaffenheit, die weder der
                    Lauf der Zeiten und Jahrhunderte noch der Wechsel und Wandel
                    dieser Welt ändern können. Sie wird so lange bestehen, wie
                    das Reich Gottes, Seine Allgewalt, Seine Herrschaft und
                    Macht bestehen werden. Sie wird die Zeichen Gottes und Seine
                    Eigenschaften offenbaren, Seine Gnade und Huld enthüllen.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese CLXV">
                    Der Tod bietet jedem vertrauenden Gläubigen den Kelch dar,
                    der in Wahrheit Leben ist. Er schenkt Freude und ist ein
                    Bote des Frohsinns. Er verleiht die Gabe ewigen Lebens.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese LXXXI">
                    Gesegnet die Seele, die zur Stunde ihrer Trennung vom Leibe
                    über die eitlen Vorstellungen der Völ­ker dieser Welt
                    geheiligt ist. Eine solche Seele lebt und wirkt im Einklang
                    mit dem Willen ihres Schöpfers und geht in das allhöchste
                    Paradies ein.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DieSeeleZitate;
